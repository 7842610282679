import { ArrayUtils } from '@klaro/corejs/utils';
import { Board, BoardAnchor } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('boardDisplayMenu', boardDisplayMenu);
function boardDisplayMenu(Colors, $filter) {
    return {
        restrict: 'E',
        template: require('@/core/board/boardDisplayMenu.html'),
        require: '^board',
        scope: true,
        link: function (scope, elm, attrs, board) {
            scope.coloredDimensions = [];
            board.$watch('board', (newBoard) => {
                if (!newBoard) {
                    return;
                }
                scope.selectedColorDim = board.getColoredDimension();
                scope.coloredDimensions = board.getColoredDimensions().sort(ArrayUtils.sorterBy(['label']));
                scope.currentMode = board.getBoard().getDisplayMode();
                scope.boardData = board.getBoard().toBoardSaveRaw();
                scope.boardBackground = {
                    selected: scope.boardData.background,
                };
                scope.showCoverImage = board.getBoard().showCoverImage();
            }, scope);
            scope.$watch('selectedColorDim', (newDim) => {
                if (!newDim) {
                    return;
                }
                const cur = board.getColoredDimension();
                if (newDim !== cur) {
                    board.setColoredDimension(newDim);
                }
            });
            scope.setColoredDimension = function (dim) {
                scope.selectedColorDim = dim;
            };
            scope.boardThemes = Colors.backgrounds().map((bg) => {
                bg.label = $filter('capitalize')(bg.id);
                bg.code = bg.id;
                bg.icon = `icon-Color-rectangle ${bg.class}`;
                return bg;
            });
            scope.$watch('boardBackground.selected', (background) => {
                if (!background) {
                    return;
                }
                if (scope.boardData.background === background) {
                    return;
                }
                return board.setBoardBackground(background);
            });
            scope.getDisplayModes = Board.getDisplayModes().map((mode) => {
                const capitalizedName = $filter('capitalize')(mode.code);
                mode.icon = `mode-icon icon-View-${capitalizedName}`;
                return mode;
            });
            scope.$watch('boardData.mode', (mode) => {
                scope.currentMode = scope.getDisplayModes.find(m => m.code === mode);
            });
            scope.toggleCompactDisplay = function () {
                board.setCompactDisplay(!scope.boardData.compactDisplay);
            };
            // Display cover image
            scope.toggleDisplayCoverImage = function () {
                const anchor = scope.boardData.anchors.cards ?? []; // an array of dimensions' codes to use in the "cards" anchor
                const boardAnchor = BoardAnchor.list(anchor, { dimensions: board.getDimensions() });
                return board
                    .ensureCoverImageDimension(false)
                    .then((dim) => {
                    if (dim) {
                        const cardsAnchors = boardAnchor.toggle(dim).toList();
                        return board.setDimensionsAt('cards', cardsAnchors);
                    }
                });
            };
        },
    };
}
