angular
    .module('klaro')
    .directive('actionsContextMenu', actionsContextMenu);
function actionsContextMenu() {
    return {
        restrict: 'E',
        template: require('@/support/actionsContextMenu.html'),
        scope: {
            actions: '=',
            position: '=?',
        },
        link: function (scope, elm, attrs) {
            scope.position = attrs.position || 'left';
            scope.parent = function ($event, method, param) {
                if (method) {
                    scope.$parent[method]($event, param);
                }
            };
            scope.cssClassFor = function (action) {
                return [action.icon, action.separator ? 'separator' : null].filter(Boolean);
            };
            scope.enabled = function (action) {
                return action.enabled === undefined || scope.$parent[action.enabled](action.param);
            };
        },
    };
}
