import Dimension from '../Dimension';
import * as Constructors from './index';
const Factory = {
    Constructors,
    DateBoundaryTypes: {
        ComputedDate: true,
        Date: true,
        DateTime: true,
        DueDate: true,
        InStateSince: true,
        RecentlyCreated: true,
        RecentlyUpdated: true,
    },
    Constructor: function (which) {
        return Factory.Constructors[which];
    },
    Default: function (raw) {
        if (raw.constructor === Dimension) {
            return raw;
        }
        return new Dimension(raw);
    },
    Identifier: function (raw) {
        const Identifier = Factory.Constructor('Identifier');
        if (raw.constructor == Identifier) {
            return raw;
        }
        return new Identifier(raw);
    },
    Title: function (raw) {
        const Title = Factory.Constructor('Title');
        if (raw.constructor == Title) {
            return raw;
        }
        return new Title(raw);
    },
    Specification: function (raw) {
        const Specification = Factory.Constructor('Specification');
        if (raw.constructor == Specification) {
            return raw;
        }
        return new Specification(raw);
    },
    ProjectMember: function (raw) {
        const ProjectMember = Factory.Constructor('ProjectMember');
        if (raw.constructor == ProjectMember) {
            return raw;
        }
        return new ProjectMember(raw);
    },
    Assignee: function (raw) {
        const Assignee = Factory.Constructor('Assignee');
        if (raw.constructor == Assignee) {
            return raw;
        }
        return new Assignee(raw);
    },
    Reporter: function (raw) {
        const Reporter = Factory.Constructor('Reporter');
        if (raw.constructor == Reporter) {
            return raw;
        }
        return new Reporter(raw);
    },
    Workspace: function (raw) {
        const Workspace = Factory.Constructor('Workspace');
        if (raw.constructor == Workspace) {
            return raw;
        }
        return new Workspace(raw);
    },
    Date: function (raw) {
        const DateDim = Factory.Constructor('DateDim');
        if (raw.constructor == DateDim) {
            return raw;
        }
        return new DateDim(raw);
    },
    DueDate: function (raw) {
        const DueDate = Factory.Constructor('DueDate');
        if (raw.constructor == DueDate) {
            return raw;
        }
        return new DueDate(raw);
    },
    InStateSince: function (raw) {
        const InStateSince = Factory.Constructor('InStateSince');
        if (raw.constructor == InStateSince) {
            return raw;
        }
        return new InStateSince(raw);
    },
    RecentlyCreated: function (raw) {
        const RecentlyCreated = Factory.Constructor('RecentlyCreated');
        if (raw.constructor == RecentlyCreated) {
            return raw;
        }
        return new RecentlyCreated(raw);
    },
    RecentlyUpdated: function (raw) {
        const RecentlyUpdated = Factory.Constructor('RecentlyUpdated');
        if (raw.constructor == RecentlyUpdated) {
            return raw;
        }
        return new RecentlyUpdated(raw);
    },
    DateTime: function (raw) {
        const DateTime = Factory.Constructor('DateTime');
        if (raw.constructor == DateTime) {
            return raw;
        }
        return new DateTime(raw);
    },
    Integer: function (raw) {
        const Integer = Factory.Constructor('Integer');
        if (raw.constructor == Integer) {
            return raw;
        }
        return new Integer(raw);
    },
    CustomOrder: function (raw) {
        const CustomOrder = Factory.Constructor('CustomOrder');
        if (raw.constructor == CustomOrder) {
            return raw;
        }
        return new CustomOrder(raw);
    },
    UserDefined: function (raw) {
        const UserDefined = Factory.Constructor('UserDefined');
        if (raw.constructor == UserDefined) {
            return raw;
        }
        return new UserDefined(raw);
    },
    Progress: function (raw) {
        const Progress = Factory.Constructor('Progress');
        if (raw.constructor == Progress) {
            return raw;
        }
        return new Progress(raw);
    },
    Kind: function (raw) {
        const Kind = Factory.Constructor('Kind');
        if (raw.constructor == Kind) {
            return raw;
        }
        return new Kind(raw);
    },
    StoryBinaryLink: function (raw) {
        const StoryBinaryLink = Factory.Constructor('StoryBinaryLink');
        if (raw.constructor == StoryBinaryLink) {
            return raw;
        }
        return new StoryBinaryLink(raw);
    },
    StoryBinaryCount: function (raw) {
        const StoryBinaryCount = Factory.Constructor('StoryBinaryCount');
        if (raw.constructor == StoryBinaryCount) {
            return raw;
        }
        return new StoryBinaryCount(raw);
    },
    Derived: function (raw) {
        const Derived = Factory.Constructor('Derived');
        if (raw.constructor == Derived) {
            return raw;
        }
        return new Derived(raw);
    },
    ComputedDate: function (raw) {
        const ComputedDate = Factory.Constructor('ComputedDate');
        if (raw.constructor == ComputedDate) {
            return raw;
        }
        return new ComputedDate(raw);
    },
    ComputedInteger: function (raw) {
        const ComputedInteger = Factory.Constructor('ComputedInteger');
        if (raw.constructor == ComputedInteger) {
            return raw;
        }
        return new ComputedInteger(raw);
    },
    Poll: function (raw) {
        const Poll = Factory.Constructor('Poll');
        if (raw.constructor == Poll) {
            return raw;
        }
        return new Poll(raw);
    },
    CoverImage: function (raw) {
        const CoverImage = Factory.Constructor('CoverImage');
        if (raw.constructor == CoverImage) {
            return raw;
        }
        return new CoverImage(raw);
    },
    Mine: function (raw) {
        const Mine = Factory.Constructor('Mine');
        if (raw.constructor == Mine) {
            return raw;
        }
        return new Mine(raw);
    },
    Text: function (raw) {
        const Text = Factory.Constructor('Text');
        if (raw.constructor == Text) {
            return raw;
        }
        return new Text(raw);
    },
    CreatedBy: function (raw) {
        const CreatedBy = Factory.Constructor('CreatedBy');
        if (raw.constructor == CreatedBy) {
            return raw;
        }
        return new CreatedBy(raw);
    },
    Archived: function (raw) {
        const Archived = Factory.Constructor('Archived');
        if (raw.constructor == Archived) {
            return raw;
        }
        return new Archived(raw);
    },
    Specification: function (raw) {
        const Specification = Factory.Constructor('Specification');
        if (raw.constructor == Specification) {
            return raw;
        }
        return new Specification(raw);
    },
    //jeny(dimension) ${DimName}: function(raw) {
    //jeny(dimension)   const ${DimName} = Factory.Constructor('${DimName}');
    //jeny(dimension)   if (raw.constructor == ${DimName}) {return raw;}
    //jeny(dimension)   return new ${DimName}(raw);
    //jeny(dimension) },
};
export default Factory;
