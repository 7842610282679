angular
    .module('klaro')
    .directive('boardOrderingSelect', boardOrderingSelect);
function boardOrderingSelect(dimensionRest, dimensionEditModal, navigation) {
    return {
        restrict: 'E',
        template: require('@/core/board/boardOrderingSelect.html'),
        require: '^board',
        scope: true,
        link: function (scope, elm, attrs, board) {
            let candidates;
            board.$watch('board', () => {
                candidates = board.getOrderingCandidates();
                scope.options = candidates.toNgOptions();
                scope.selected = candidates.currentNgOption();
            }, scope);
            scope.setOrdering = function (opt) {
                if (!candidates) {
                    return;
                }
                if (!candidates.isCurrentNgOption(opt)) {
                    if (opt.code === 'newDimension') {
                        const onDimensionAdded = function (d) {
                            return board.addNewDimension(d).then(() => {
                                board.sortBy(d);
                            });
                        };
                        dimensionEditModal
                            .open(dimensionRest.newOne())
                            .result
                            .then(onDimensionAdded)
                            .catch(navigation.noop);
                    }
                    else if (opt.code === 'useComplex') {
                        board.toggleBoardSettings('cards-ordering', {
                            skipDirtyCover: true,
                        });
                    }
                    else if (opt.code === 'useCustom') {
                        board.ensureSortByCustomOrder();
                    }
                    else if (opt.anchor) {
                        board.sortBy(opt.anchor);
                    }
                }
            };
        },
    };
}
