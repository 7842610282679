angular
    .module('klaro')
    .service('dimensionEditModal', dimensionEditModal)
    .controller('DimensionEditModalController', DimensionEditModalController);
function dimensionEditModal(klaroModal, dimensionRest) {
    return {
        open: function (dimension, dimensions, installersFilter) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/dimension/dimensionEditModal.html'),
                controller: 'DimensionEditModalController',
                windowClass: 'klaro-modal',
                size: 'small',
                resolve: {
                    dimension: function () {
                        return dimension;
                    },
                    dimensions: function () {
                        if (dimensions) {
                            return dimensions;
                        }
                        else {
                            return dimensionRest.list();
                        }
                    },
                    installers: function () {
                        return dimensionRest
                            .installers()
                            .then((is) => {
                            return installersFilter ? is.filter(installersFilter) : is;
                        });
                    },
                },
            });
        },
    };
}
function DimensionEditModalController($scope, $uibModalInstance, dimensions, dimension, dimensionRest, robust, confirm, installers, workspaceRest) {
    $scope.dimensions = dimensions;
    $scope.dimension = dimension;
    $scope.formData = angular.copy(dimension.toRaw());
    $scope.title = isNew() ? 'New dimension' : 'Edit dimension';
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.commit = function (dimension) {
        $uibModalInstance.close(dimension);
    };
    // datatypes
    $scope.installers = installers;
    $scope.selectedInstaller = $scope.dimension.getBestInstallerAmong(installers);
    $scope.focusOnName = function () {
        document.getElementById('dimensionNameInput').focus();
    };
    // When an installer is set, set its datatype in the dimension data
    // to send to backend later, and set a proposed dimension name if that
    // makes sense.
    $scope.$watch('selectedInstaller', (i, oldI) => {
        if (!i) {
            return;
        }
        $scope.formData.datatype = i.datatype;
        if (!$scope.formData.label || (oldI && $scope.formData.label == oldI.proposedName)) {
            $scope.formData.label = i.proposedName;
        }
        if (i.valuesAtCreation) {
            $scope.formData.values = angular.copy(i.exampleValues);
        }
        if (isNew()) {
            $scope.formData.tagging = i.tagging;
        }
        $scope.dimension = dimensionRest.dress($scope.formData);
    });
    // Saving
    $scope.saveButtonLabel = function () {
        return isNew() ? 'Create dimension' : 'Save';
    };
    $scope.canSave = function () {
        return isNew() || isDirty();
    };
    $scope.save = function () {
        const newDimension = dimensionRest.dress($scope.formData);
        dimensionRest
            .save(dimension, newDimension)
            .then($scope.commit)
            .catch(onError);
    };
    // Numbers option
    $scope.isUserDefinedSemantics = function () {
        return $scope.dimension.isUserDefinedSemantics();
    };
    $scope.requiresFormalDef = function () {
        return $scope.dimension.requiresFormaldef();
    };
    $scope.allowsValuesAtCreation = function () {
        const si = $scope.selectedInstaller;
        return isNew() && si && si.valuesAtCreation;
    };
    $scope.isBinaryStoryLink = function () {
        return $scope.formData.datatype === 'StoryBinaryLink';
    };
    $scope.isBinaryStoryCount = function () {
        return $scope.formData.datatype === 'StoryBinaryCount';
    };
    $scope.isKind = function () {
        return $scope.formData.datatype === 'Kind';
    };
    $scope.isTrackMember = function () {
        return $scope.formData.datatype === 'ProjectMember';
    };
    $scope.$watch('formData.required', () => {
        if (!$scope.isBinaryStoryLink()) {
            return;
        }
        $scope.formData.datatypeOptions.hideOnChild = false;
    });
    $scope.isNumbersOn = function () {
        return $scope.formData.semanticsType === 'Integer';
    };
    $scope.toggleNumbersOption = function () {
        if ($scope.isNumbersOn()) {
            $scope.formData.semanticsType = null;
        }
        else {
            $scope.formData.semanticsType = 'Integer';
        }
        $scope.dimensionForm.$setDirty();
    };
    // Restrict option
    $scope.kindDimension = dimensions.find((d) => {
        return d.datatype === 'Kind';
    });
    let workspaces = [];
    let _gettingWorkspaces = false;
    $scope.workspaces = function () {
        if (_gettingWorkspaces) {
            return _gettingWorkspaces;
        }
        else if (workspaces.length) {
            return workspaces;
        }
        else {
            _gettingWorkspaces = workspaceRest.list()
                .then((workspaceList) => {
                _gettingWorkspaces = false;
                workspaces = workspaceList.map((ws) => {
                    return {
                        'id': ws.id,
                        'label': ws.name,
                    };
                });
                return workspaces;
            })
                .catch(onError);
        }
    };
    $scope.isMemberRestrictOn = function () {
        return $scope.formData.datatypeOptions.workspace;
    };
    $scope._isRestrictOn = (function () {
        return $scope.kindDimension
            && $scope.formData.relevantKinds
            && $scope.formData.relevantKinds.length > 0;
    })();
    $scope.isRestrictOn = function () {
        return $scope._isRestrictOn;
    };
    $scope.toggleRestrictOption = function () {
        $scope._isRestrictOn = !$scope._isRestrictOn;
        if (!$scope._isRestrictOn) {
            $scope.formData.relevantKinds = [];
        }
        $scope.dimensionForm.$setDirty();
    };
    $scope.toggleMemberRestrictOption = function () {
        if ($scope.isMemberRestrictOn()) {
            delete $scope.formData.datatypeOptions.workspace;
        }
        else {
            $scope.formData.datatypeOptions.workspace = 'undefined';
        }
        $scope.dimensionForm.$setDirty();
    };
    // Disabling
    $scope.canDisable = function () {
        return !isNew() && !isDeleted() && !$scope.dimension.isPhantomDimension();
    };
    $scope.disable = function () {
        confirm({
            h2: `Disable *${$scope.dimension.label}*`,
            hint: 'No information is lost. The dimension disappears, but you can re-activate it anytime. Are you sure?',
        }, () => {
            dimensionRest
                .del($scope.dimension)
                .then($scope.commit)
                .catch(onError);
        });
    };
    $scope.enable = function () {
        dimensionRest
            .enable($scope.dimension)
            .then($scope.commit)
            .catch(onError);
    };
    // Predicates
    function isNew() {
        return $scope.formData.id === null || $scope.formData.id === undefined;
    }
    $scope.isNew = isNew;
    function isDeleted() {
        return $scope.formData.id && $scope.formData.deleted;
    }
    function isDirty() {
        return $scope.dimensionForm.$dirty;
    }
    function onError(err) {
        $scope.error = robust.message(err.data);
    }
}
