angular
    .module('klaro')
    .directive('newStoryLink', newStoryLink);
function newStoryLink() {
    return {
        restrict: 'E',
        require: '^board',
        template: require('@/core/board/newStoryLink.html'),
        replace: true,
        link: function (scope, elm, attrs, boardCtrl) {
            scope.$watch('board', () => {
                if (boardCtrl.canWrite()) {
                    elm.show();
                }
                else {
                    elm.hide();
                }
            });
        },
    };
}
